import $ from 'jquery';
import tmpl from 'blueimp-tmpl';

function busca(empresaId, epidemiaId) {
    const apiUrl = 'https://app.benjimed.com.br/api/monitoramento-epidemia/';
    // const apiUrl = 'http://localhost/api/monitoramento-epidemia/';
    let intervalId = null;

    function buscaDados(empresaId, epidemiaId) {
        const $dados = $(`#dados`);

        $dados.html(`<p id="loader" class="loader">carregando...</p>`);

        $.ajax(`${apiUrl}dados?empresa=${empresaId}&epidemia=${epidemiaId}`)
            .then((result) => {
                $(`#loader`).remove();
                $dados.html(tmpl('tmpl-resultado', result));
            })
            .catch((error) => {
                alert('Falha de comunicação.');
            });
    };

    buscaDados(empresaId, epidemiaId);

    window.setInterval(() => {
        buscaDados(empresaId, epidemiaId);
    }, 600000);
}

export default busca;
